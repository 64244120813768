import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {COLORS, CURRENCIES_OPTIONS, INFO, TRANSLATION_KEYS} from "../../../util/constants";
import {NEW_COST_CHARGE_INITIAL_STATE, NEW_COST_INITIAL_STATE} from "../../../models/models";
import {validateNumericInputWithComma} from "../../../util/functions";
import {ActionIcon, Flex, Group, Input, rem, ScrollArea, Select, Text, TextInput, Tooltip} from "@mantine/core";
import {LanguageContext} from "../../../context/LanguageContext";
import {notifications} from "@mantine/notifications";
import ModalInput from "../../../components/modal/ModalInput";
import {faClone, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../../components/base/Button/Button";

function EditableCostTable({
                               quotationId,
                               transportMode,
                               mode,
                               chargeable,
                               provider,
                               chargeCodes,
                               defaultChargeCodes,
                               data,
                               setData,
                               reference,
                               setReference
                           }) {
    const {t} = useContext(LanguageContext);
    const [currencyTotals, setCurrencyTotals] = useState([]);
    const [charges, setCharges] = useState([]);
    const conceptOptions = useMemo(() => chargeCodes?.map((chargeCode) => ({
        value: chargeCode.concept,
        label: chargeCode.shortDescription?.toUpperCase(),
        description: chargeCode.description
    })), [chargeCodes]);
    const [commentsModal, setCommentsModal] = useState({show: false, data: '', costIndex: null});
    const refDescriptions = useRef([]);

    useEffect(() => {
        if (data.length > 0) {
            return;
        }
        if (!provider || !chargeCodes || !quotationId) {
            return;
        }

        const initialCharges = defaultChargeCodes?.map((charge) => ({
            concept: charge.concept,
            description: chargeCodes.find((chargeCode) => chargeCode.concept === charge.concept)?.description
        }));
        setData([
            {
                ...NEW_COST_INITIAL_STATE,
                idCotizacion: quotationId,
                idAgente: provider.id,
                charges: defaultChargeCodes?.length > 0 ?
                    initialCharges?.map((charge, chargeIndex) => ({
                        ...NEW_COST_CHARGE_INITIAL_STATE,
                        concept: charge.concept,
                        description: charge.description,
                        chargeIndex: chargeIndex + 1
                    }))
                    :
                    [{...NEW_COST_CHARGE_INITIAL_STATE}]
            }
        ]);
        setCharges(defaultChargeCodes?.length > 0 ? [...initialCharges] : [{concept: '', description: ''}]);
    }, [quotationId, chargeCodes, provider]);

    useEffect(() => {
        calculateCurrencyTotals(data)
    }, [data]);

    function calculateCurrencyTotals(data) {
        const totals = data.map(() => ({USD: 0, EUR: 0, ARS: 0}));

        data.forEach((cost, costIndex) => {
            cost.charges.forEach((charge) => {
                const amount = parseFloat(charge.amount);
                const currency = charge.currency;
                if (!isNaN(amount) && currency) {
                    totals[costIndex][currency] += amount;
                }
            });
        });

        setCurrencyTotals(totals);
    }

    function handleCostChange(costIndex, name, value) {
        const newCosts = data.map((cost, auxCostIndex) => {
            if (auxCostIndex === costIndex) {
                const newCost = {...cost};
                newCost[name] = value;
                return newCost;
            }
            return cost;
        });
        setData(newCosts);
    }

    function handleCostChargeChange(chargeIndex, costIndex, name, value) {
        const newCosts = data.map((cost, auxCostIndex) => {
            if (auxCostIndex === costIndex) {
                const newCost = {...cost};
                newCost.charges[chargeIndex][name] = value;
                return newCost;
            }
            return cost;
        });
        setData(newCosts);
    }

    function handleNumericCostChargeChange(chargeIndex, costIndex, name, value) {
        handleCostChargeChange(chargeIndex, costIndex, name, validateNumericInputWithComma(value));
    }

    function handleCostPerKgChange(chargeIndex, costIndex, value) {
        const newValue = validateNumericInputWithComma(value);
        const newCosts = data.map((cost, auxCostIndex) => {
            if (auxCostIndex === costIndex) {
                const newCost = {...cost};
                newCost.charges[chargeIndex].costPerKg = newValue;
                return newCost;
            }
            return cost;
        });
        setData(newCosts);
        calculateCurrencyTotals(newCosts);

        let newCost;
        if (transportMode === 'AIR') {
            newCost = newValue * Number(chargeable);
        } else if (mode === 'LCL') {
            newCost = newValue * Number(chargeable / 1000);
        }
        handleCostChargeChange(chargeIndex, costIndex, 'amount', newCost ? newCost.toFixed(2) : '');
    }

    function handleConceptChange(chargeIndex, value) {
        const newDescription = conceptOptions.find((concept) => concept.value === value)?.description;

        const newCosts = data.map((cost) => {
            const newCost = {...cost};
            newCost.charges[chargeIndex].concept = value;
            newCost.charges[chargeIndex].description = newDescription;
            return newCost;
        });
        const newCharges = charges.map((charge, auxChargeIndex) => {
            if (auxChargeIndex === chargeIndex) {
                const newCharge = {...charge};
                newCharge.concept = value;
                newCharge.description = newDescription;
                return newCharge;
            }
            return charge;
        });

        refDescriptions.current[chargeIndex].focus();

        setCharges(newCharges);
        setData(newCosts);
    }

    function handleDescriptionChange(chargeIndex, value) {
        const newCosts = data.map((cost) => {
            const newCost = {...cost};
            newCost.charges[chargeIndex].description = value;
            return newCost;
        });
        const newCharges = charges.map((charge, auxChargeIndex) => {
            if (auxChargeIndex === chargeIndex) {
                const newCharge = {...charge};
                newCharge.description = value;
                return newCharge;
            }
            return charge;
        });

        setCharges(newCharges);
        setData(newCosts);
    }

    const onChangeDate = (costIndex, infoKey, value) => {
        let input = value.replace(/\D/g, '');

        if (input.length <= 2) {
            handleCostChange(costIndex, infoKey, input);
        } else if (input.length <= 4) {
            if (Number(input.slice(2)) <= 12 && Number(input.slice(2)) >= 0) {
                handleCostChange(costIndex, infoKey, `${input.slice(0, 2)}/${input.slice(2)}`);
            }
        } else {
            handleCostChange(costIndex, infoKey, `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4, 8)}`);
        }
    }

    function removeChargeFromAllCosts(chargeIndex) {
        if (charges.length === 1) {
            notifications.show({
                title: t(TRANSLATION_KEYS.ERROR_ALERT),
                message: t(TRANSLATION_KEYS.ERROR_AT_LEAST_ONE_CHARGE),
                color: "red",
            });
            return;
        }

        const newCharges = [...charges];
        newCharges.splice(chargeIndex, 1);

        const newCosts = data.map((cost) => {
            const newCost = {...cost};
            newCost.charges.splice(chargeIndex, 1);
            return newCost;
        });

        setCharges(newCharges);
        reCalculateChargesIndexes(newCosts);
    }

    function addChargeToAllCosts() {
        if (validateChargeCodes()) {
            const newCosts = data.map((cost) => {
                const newCost = {...cost};
                const newCharge = {...NEW_COST_CHARGE_INITIAL_STATE};
                newCost.charges.push(newCharge);
                return newCost;
            });
            setCharges(charges.concat({concept: '', description: ''}));
            reCalculateChargesIndexes(newCosts);
        } else {
            notifications.show({
                title: t(TRANSLATION_KEYS.ERROR_ALERT),
                message: t(TRANSLATION_KEYS.ERROR_CANNOT_ADD_MORE_CONCEPTS),
                color: "red",
            });
        }
    }

    function validateChargeCodes() {
        return charges.every((charge) => charge.concept !== '');
    }

    function renderCostCell(cost, costIndex, chargeIndex) {
        let charge = cost.charges[chargeIndex];
        return (
            <div className={"default-table-cell d-flex flex-row gap-1 w-100 justify-content-center"}>
                {(transportMode === "AIR" || mode === "LCL") &&
                    !(!charge?.costPerKg && (charge?.amount || parseFloat(charge?.amount)))
                    &&
                    <Tooltip multiline w={260} label={t(TRANSLATION_KEYS.TOOLTIP_COST_PER_KG)} withArrow color="gray">
                        <TextInput
                            size={"xs"}
                            classNames={{input: "default-table-cell"}}
                            value={charge?.costPerKg || ''}
                            onChange={(e) => {
                                handleCostPerKgChange(chargeIndex, costIndex, e.target.value)
                            }}
                            style={{width: rem(52)}}
                        />
                    </Tooltip>
                }
                <TextInput
                    size={"xs"}
                    classNames={{input: "default-table-cell"}}
                    value={cost.charges[chargeIndex]?.amount || ''}
                    disabled={charge?.costPerKg !== '' && charge?.costPerKg !== undefined && charge?.costPerKg > 0}
                    onChange={(e) => {
                        handleNumericCostChargeChange(chargeIndex, costIndex, 'amount', e.target.value)
                    }}
                    style={{width: rem(96)}}
                />
                <Select
                    size={"xs"} allowDeselect={false}
                    value={charge?.currency || 'USD'}
                    onChange={(value) => handleCostChargeChange(chargeIndex, costIndex, 'currency', value)}
                    data={CURRENCIES_OPTIONS}
                    style={{width: rem(70)}}
                    comboboxProps={{width: rem(75)}}
                />
            </div>
        );
    }

    const getInfoDescription = (infoKey) => {
        switch (infoKey) {
            case 'description':
                return t(TRANSLATION_KEYS.DESCRIPTION)?.toUpperCase();
            case 'validez':
                return t(TRANSLATION_KEYS.VALIDITY)?.toUpperCase();
            case 'ruta':
                return t(TRANSLATION_KEYS.ROUTE)?.toUpperCase();
            case 'ttime':
                return t(TRANSLATION_KEYS.TRANSIT_TIME)?.toUpperCase();
            case 'carrier':
                return t(TRANSLATION_KEYS.CARRIER)?.toUpperCase();
            case 'freeDays':
                return t(TRANSLATION_KEYS.FREE_DAYS)?.toUpperCase();
        }
    }

    function handleAddCostClick() {
        const newCostData = [...data];
        const newCost = {
            ...NEW_COST_INITIAL_STATE,
            idCotizacion: quotationId,
            idAgente: provider.id,
            charges: charges.map((charge) => ({
                ...NEW_COST_CHARGE_INITIAL_STATE,
                concept: charge.concept,
                description: charge.description
            }))
        };
        const newCurrencyTotals = [...currencyTotals];

        newCurrencyTotals.splice(data.length + 1, 0, {USD: 0, EUR: 0, ARS: 0});
        newCostData.splice(data.length + 1, 0, newCost);

        setCurrencyTotals(newCurrencyTotals);
        reCalculateChargesIndexes(newCostData);
    }

    function handleDeleteCostClick(index) {
        // Validate that each agent has at least one cost
        if (data.length === 1) {
            notifications.show({
                title: t(TRANSLATION_KEYS.ERROR_ALERT),
                message: t(TRANSLATION_KEYS.ERROR_AT_LEAST_ONE_COST),
                color: "red",
            });
            return;
        }

        const newCostData = [...data];
        const newCurrencyTotals = [...currencyTotals];

        newCurrencyTotals.splice(index, 1);
        newCostData.splice(index, 1);

        setCurrencyTotals(newCurrencyTotals);
        reCalculateChargesIndexes(newCostData);
    }

    function handleDuplicateCostClick(index) {
        const newCostData = [...data];
        const newCost = {
            ...newCostData[index],
            charges: newCostData[index].charges.map((charge) => ({
                ...charge,
            }))
        };
        const newCurrencyTotals = [...currencyTotals];

        newCurrencyTotals.splice(index + 1, 0, {USD: 0, EUR: 0, ARS: 0});
        newCostData.splice(index + 1, 0, newCost);

        setCurrencyTotals(newCurrencyTotals);
        reCalculateChargesIndexes(newCostData);
    }

    function handleCommentsClick(costIndex, e) {
        e.preventDefault();
        setCommentsModal({show: true, data: data[costIndex].comments, costIndex: costIndex});
    }

    function handleCommentsModalClose() {
        setCommentsModal({show: false, data: '', costIndex: null});
    }

    function handleCommentsChange(value) {
        const newCosts = data.map((cost, auxCostIndex) => {
            if (auxCostIndex === commentsModal.costIndex) {
                const newCost = {...cost};
                newCost.comments = value;
                return newCost;
            }
            return cost;
        });

        setData(newCosts);
        setCommentsModal(prevState => ({...prevState, data: value}));
    }

    function reCalculateChargesIndexes(newData) {
        const updatedData = newData.map((cost) => {
            const newCost = {...cost};
            newCost.charges = cost.charges.map((charge, chargeIndex) => {
                const newCharge = {...charge};
                newCharge.chargeIndex = chargeIndex + 1;
                return newCharge;
            });
            return newCost;
        });
        setData(updatedData);
    }

    return (
        <>
            {commentsModal.show &&
                <ModalInput label={t(TRANSLATION_KEYS.COMMENTS_AND_CONDITIONS)} data={commentsModal.data}
                            setData={handleCommentsChange} readOnly={false}
                            onClose={handleCommentsModalClose}/>
            }
            <div className={"d-flex flex-column gap-2"}>
                <div className={"d-flex flex-row justify-content-between w-100"}>
                    <Tooltip label={t(TRANSLATION_KEYS.TOOLTIP_PROVIDER_REFERENCE)}
                             withArrow color="gray" multiline w={200} zIndex={99999}>
                        <TextInput
                            size={"xs"}
                            label={t(TRANSLATION_KEYS.PROVIDER_REFERENCE)}
                            value={reference}
                            onChange={(e) => setReference(e.target.value)}
                        />
                    </Tooltip>
                    <Button size={"xs"} className={"primary"} onClick={handleAddCostClick}>
                        {t(TRANSLATION_KEYS.ADD_NEW_COST)}
                    </Button>
                </div>
                <ScrollArea type="auto">
                    <div className={"customTableContainerExtraPadding"}>
                        <table>
                            <thead>
                            <tr>
                                <th/>
                                <th>
                                    {t(TRANSLATION_KEYS.CODE)?.toUpperCase()}
                                </th>
                                <th>{t(TRANSLATION_KEYS.CONCEPT_EDITABLE)?.toUpperCase()}</th>
                                {data.map((cost, index) => (
                                    <th style={{cursor: 'default'}}>
                                        <Group justify={"center"}>
                                            <Tooltip label={t(TRANSLATION_KEYS.TOOLTIP_DELETE_COST)}
                                                     withArrow color="gray">
                                                <ActionIcon color={"red"} size={"xs"}
                                                            onClick={() => handleDeleteCostClick(index)}>
                                                    <FontAwesomeIcon icon={faMinus} size={"2xs"}/>
                                                </ActionIcon>
                                            </Tooltip>
                                            {t(TRANSLATION_KEYS.COST).toUpperCase()} {index + 1}
                                            <Tooltip label={t(TRANSLATION_KEYS.TOOLTIP_DUPLICATE_COST)}
                                                     withArrow color="gray" multiline w={200}>
                                                <ActionIcon size={"xs"} onClick={() => handleDuplicateCostClick(index)}>
                                                    <FontAwesomeIcon icon={faClone} size={"2xs"}/>
                                                </ActionIcon>
                                            </Tooltip>
                                        </Group>
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody style={{textAlign: 'center'}}>
                            {charges.map((charge, chargeIndex) => (
                                <tr key={charge.idCharge}>
                                    <td style={{paddingBottom: '2px', paddingTop: '2px'}}>
                                        <Tooltip label={t(TRANSLATION_KEYS.TOOLTIP_DELETE_CHARGE)}
                                                 withArrow color="gray">
                                            <ActionIcon color={"red"} size={"xs"}
                                                        onClick={() => removeChargeFromAllCosts(chargeIndex)}>
                                                <FontAwesomeIcon icon={faMinus} size={"2xs"}/>
                                            </ActionIcon>
                                        </Tooltip>
                                    </td>
                                    <td style={{paddingBottom: '2px', paddingTop: '2px'}}>
                                        <div className={"d-flex justify-content-center w-100"}>
                                            <Select
                                                size={"xs"}
                                                style={{width: rem(115)}}
                                                comboboxProps={{width: rem(250)}}
                                                data={conceptOptions}
                                                renderOption={({option}) => (
                                                    <Group flex="1" gap="xs">
                                                        {option.description}
                                                    </Group>
                                                )}
                                                value={charge.concept}
                                                onChange={(value) => handleConceptChange(chargeIndex, value)}
                                            />
                                        </div>
                                    </td>
                                    <td style={{paddingBottom: '2px', paddingTop: '2px'}}>
                                        <div className={"d-flex justify-content-center w-100"}>
                                            <TextInput
                                                size={"xs"}
                                                style={{width: rem(200)}}
                                                value={charge.description}
                                                ref={(element) => refDescriptions.current[chargeIndex] = element}
                                                onChange={(e) => handleDescriptionChange(chargeIndex, e.target.value)}
                                            />
                                        </div>
                                    </td>

                                    {data.map((cost, costIndex) => (
                                        <td style={{paddingBottom: '2px', paddingTop: '2px'}}>
                                            {renderCostCell(cost, costIndex, chargeIndex)}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            <tr>
                                <td style={{paddingTop: '2px'}}>
                                    <Tooltip label={t(TRANSLATION_KEYS.TOOLTIP_ADD_CHARGE)} withArrow
                                             color="gray">
                                        <ActionIcon color={"green"} size={"xs"}
                                                    onClick={addChargeToAllCosts}>
                                            <FontAwesomeIcon icon={faPlus} size={"2xs"}/>
                                        </ActionIcon>
                                    </Tooltip>
                                </td>
                                <td/>
                                <td style={{paddingBottom: '2px', paddingTop: '2px'}}>
                                    <Text fw={750} size={"xs"}
                                          style={{color: COLORS.PRIMARY}}>{t(TRANSLATION_KEYS.TOTAL)?.toUpperCase()}</Text>
                                </td>
                                {data.map((_cost, costIndex) => (
                                    <td style={{paddingBottom: '2px', paddingTop: '2px'}}>
                                        {currencyTotals[costIndex] &&
                                            Object.entries(currencyTotals[costIndex]).map(([currency, total]) => (
                                                total !== 0 && (
                                                    <Text fw={750} size={"xs"}
                                                          style={{color: COLORS.PRIMARY}}>{total.toFixed(2)} {currency}</Text>
                                                )
                                            ))}
                                    </td>
                                ))}
                            </tr>
                            <tr style={{height: '15px'}}/>
                            {INFO.map((infoKey) => (
                                <tr>
                                    <td/>
                                    <td/>
                                    <td style={{paddingBottom: '2px', paddingTop: '2px'}}>
                                        <Flex justify="center" align="center" mih={rem(30)}>
                                            <Text fw={750} size={"xs"} style={{color: COLORS.PRIMARY}}>
                                                {getInfoDescription(infoKey)}
                                            </Text>
                                        </Flex>
                                    </td>
                                    {data.map((cost, costIndex) => (
                                        <td style={{paddingBottom: '2px', paddingTop: '2px'}}>
                                            <div className={"d-flex justify-content-center w-100"}>
                                                <TextInput
                                                    size={"xs"}
                                                    placeholder={infoKey === 'validez' ? 'dd/mm/yyyy' : ''}
                                                    classNames={{input: "default-table-cell"}}
                                                    value={cost[infoKey]}
                                                    onChange={
                                                        infoKey === 'validez' ?
                                                            (e) => {
                                                                onChangeDate(costIndex, infoKey, e.target.value)
                                                            }
                                                            :
                                                            (e) => {
                                                                handleCostChange(costIndex, infoKey, e.target.value)
                                                            }
                                                    }
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            <tr>
                                <td/>
                                <td/>
                                <td style={{paddingBottom: '2px', paddingTop: '2px'}}>
                                    <Flex justify="center" align="center" mih={rem(30)}>
                                        <Text fw={750} size={"xs"} style={{color: COLORS.PRIMARY}}>
                                            {t(TRANSLATION_KEYS.COMMENTS_AND_CONDITIONS)?.toUpperCase()}
                                        </Text>
                                    </Flex>
                                </td>
                                {data.map((cost, costIndex) => (
                                    <td style={{paddingBottom: '2px', paddingTop: '2px'}}>
                                        <div className={"d-flex justify-content-center w-100"}>
                                            <Input
                                                size={"xs"}
                                                classNames={{input: "default-table-cell"}}
                                                styles={{input: {textOverflow: "ellipsis"}}}
                                                value={cost.comments.replace(/\n/g, '  ')}
                                                pointer={true}
                                                readOnly
                                                onClick={(e) => handleCommentsClick(costIndex, e)}
                                            />
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </ScrollArea>
            </div>
        </>
    )
}

export default EditableCostTable;