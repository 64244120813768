import React, {useEffect, useState} from 'react';
import {PERMISSION} from '../../../util/constants';
import {NEW_COST_CHARGE_INITIAL_STATE} from "../../../models/models";
import chargeCodeController from "../../../controller/chargeCodeController";
import {getAuth, validateNumericInput} from "../../../util/functions";
import {rem} from "@mantine/core";
import Button from "../../../components/base/Button/Button";
import Select from "../../../components/base/Select/Select";
import TextInput from "../../../components/base/TextInput/TextInput";
import Text from "../../../components/base/Text/Text";

function CostSellExpress({shipment, updateShipmentData, editable}) {
    const [costTotal, setCostTotal] = useState(0);
    const [sellTotal, setSellTotal] = useState(0);
    const [expressChargeCodes, setExpressChargeCodes] = useState([]);

    useEffect(() => {
        chargeCodeController.getChargeCodes({transportMode: "EXPRESS"})
            .then((data) => {
                // filter out the charges that are already in the shipment
                data = data.filter((charge) => {
                    return !shipment?.sell?.charges.find((c) => c.concept === charge.concept);
                });
                setExpressChargeCodes(data);
            });
    }, [shipment?.id]);

    useEffect(() => {
        const total = getTotal(shipment?.sell);
        setSellTotal(total);

        const costTotal = getTotal(shipment?.cost);
        setCostTotal(costTotal);
    }, [shipment])

    function getTotal(data) {
        let total = 0;
        if (data) {
            data.charges.forEach((charge) => {
                total += charge.amount ? parseFloat(charge.amount) : 0;
            });
        }

        return total;
    }

    function handleAddChargeClick() {
        const newSell = {...shipment.sell};
        const newCharge = {...NEW_COST_CHARGE_INITIAL_STATE, editable: true};
        const newCost = {...shipment.cost};

        newCost.charges.push({...newCharge});
        newSell.charges.push({...newCharge});

        updateShipmentData({sell: newSell, cost: newCost});
    }

    function handleConceptChange(chargeIndex, value) {
        const newDescription = expressChargeCodes.find((concept) => concept.concept === value)?.description;

        const newSell = {...shipment.sell};
        newSell.charges[chargeIndex].concept = value;
        newSell.charges[chargeIndex].description = newDescription;

        const newCost = {...shipment.cost};
        newCost.charges[chargeIndex].concept = value;
        newCost.charges[chargeIndex].description = newDescription;

        updateShipmentData({sell: newSell, cost: newCost});
    }

    function handleCostChange(chargeIndex, amount) {
        const newCost = {...shipment.cost};
        newCost.charges[chargeIndex].amount = validateNumericInput(amount);

        updateShipmentData({cost: newCost});
    }

    function removeCharge(chargeIndex) {
        const newSell = {...shipment.sell};
        newSell.charges.splice(chargeIndex, 1);

        const newCost = {...shipment.cost};
        newCost.charges.splice(chargeIndex, 1);

        updateShipmentData({sell: newSell, cost: newCost});
    }

    return (
        <div className={"customTableContainerExtraPadding"}>
            <table>
                <thead>
                <tr>
                    <th style={{width: '60px'}}></th>
                    <th style={{width: "40%"}}>CONCEPTO</th>
                    <th style={{width: "30%"}}>FEDEX</th>
                    <th style={{width: "30%"}}>VENTA</th>
                </tr>
                </thead>
                <tbody style={{textAlign: 'center'}}>
                {shipment?.sell?.charges.map((charge, chargeIndex) => {
                    const costCharge = shipment?.cost?.charges.find((c) => c.concept === charge.concept);
                    const costAmount = costCharge ? costCharge.amount : undefined;

                    const sellCharge = shipment?.sell?.charges.find((c) => c.concept === charge.concept);
                    const sellAmount = sellCharge ? sellCharge.amount : undefined;

                    return (
                        <tr key={charge.concept}>
                            <td>
                                {costCharge?.editable &&
                                    <Button bgVariant={"error"} sizeVariant={22}
                                            onClick={() => removeCharge(chargeIndex)}>
                                        -
                                    </Button>
                                }
                            </td>
                            <td>
                                {costCharge?.editable ? (
                                    <div className={"d-flex justify-content-center"}>
                                        <Select
                                            style={{width: rem(80)}}
                                            comboboxProps={{width: rem(250)}}
                                            data={expressChargeCodes.map((concept) => ({
                                                value: concept.concept,
                                                label: concept.concept + ' - ' + concept.description
                                            }))}
                                            value={charge.concept}
                                            disabled={!getAuth(PERMISSION.MODIFICAR_COTIZACION_COSTOS)[0]}
                                            onChange={(value) => handleConceptChange(chargeIndex, value)}
                                        />
                                    </div>
                                ) : (
                                    <Text fw={400} cVariant={"surface-4"}>{charge.description}</Text>
                                )}
                            </td>
                            <td style={{paddingBottom: '2px', paddingTop: '2px'}}>
                                {costCharge?.editable ? (
                                    <div className={"d-flex justify-content-center"}>
                                        <TextInput
                                            style={{width: rem(80)}}
                                            value={costAmount || ''}
                                            onChange={(e) => handleCostChange(chargeIndex, e.target.value)}
                                            rightSectionText={"USD"}
                                        />
                                    </div>
                                ) : (
                                    <Text fw={400} cVariant={"surface-4"}>{costAmount && `${costAmount} USD`}</Text>
                                )}
                            </td>
                            <td>
                                <Text fw={400} cVariant={"surface-4"}>{sellAmount && `${sellAmount} USD`}</Text>
                            </td>
                        </tr>
                    );
                })}
                <tr>
                    <td>
                        {getAuth(PERMISSION.MODIFICAR_COTIZACION_VENTAS)[0] && editable &&
                            <Button bgVariant={"success"} sizeVariant={22} onClick={handleAddChargeClick}>
                                +
                            </Button>
                        }
                    </td>
                    <td>
                        <Text fw={750} cVariant={"primary"}>TOTAL</Text>
                    </td>
                    <td>
                        <Text fw={750} cVariant={"primary"}>{costTotal.toFixed(2)} USD</Text>
                    </td>
                    <td>
                        <Text fw={750} cVariant={"primary"}>{sellTotal.toFixed(2)} USD</Text>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default CostSellExpress;


