import React, {useEffect, useState} from 'react';
import {Combobox, InputBase, Loader, useCombobox} from "@mantine/core";
import organizationController from "../../controller/organizationController";
import {getAuth, isStringEmpty} from "../../util/functions";
import Text from "../base/Text/Text";

/**
 * @param defaultValue {string} - The default value of the input
 * @param organization {object} - The selected organization
 * @param label {string} - The label of the input
 * @param size {string} - The MantineSize of the input
 * @param setOrganization {function} - The function to set the selected organization
 * @param runOnBlur {function} - The function to run on blur in addition to the default behavior which is to close the dropdown and select the first option
 * @param disabled {boolean} - If the input is disabled
 */
function OrganizationAsyncSelect({
                                     defaultValue,
                                     organization,
                                     setOrganization,
                                     label,
                                     size = "xs",
                                     disabled = false,
                                     runOnBlur = null,
                                     ...props
                                 }) {
    const [defaultValueChanged, setDefaultValueChanged] = useState(false);
    const [firstDataFetch, setFirstDataFetch] = useState(true);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [isNew, setIsNew] = useState(false);

    const combobox = useCombobox();

    useEffect(() => {
        if (disabled) return;
        if (search === defaultValue && organization?.id > 1) return;
        if (search) {
            if (search?.trim().length > 2) {
                setLoading(true);
                organizationController.getClients(search.trim(), getAuth()[1])
                    .then((response) => {
                        setData(response);
                        combobox.resetSelectedOption();
                    })
                    .finally(() => setLoading(false));
            }
        }
    }, [search]);

    useEffect(() => {
        if (!firstDataFetch) return;
        if (data?.length) setFirstDataFetch(false);

        if (search === defaultValue && data[0]?.id !== 1) {
            setOrganization({
                id: data[0]?.id,
                nombreFantasia: data[0]?.nombre,
                newClient: false
            });
            setIsNew(false)
        }
    }, [data]);

    useEffect(() => {
        if (defaultValue && !defaultValueChanged) {
            setDefaultValueChanged(true);
            setSearch(defaultValue);
        }
    }, [defaultValue]);

    const options = data.map((org) => (
        <Combobox.Option value={org.id} key={org.id}>
            {org.nombre}
        </Combobox.Option>
    ));

    return (
        <Combobox
            store={combobox}
            size={size}
            zIndex={9999999999}
            onOptionSubmit={(val, value) => {
                if (val === '1') {
                    setData((current) => [...current, search]);
                    setOrganization({id: 1, nombreFantasia: search, newClient: true});
                    setIsNew(true)
                } else if (val !== undefined) {
                    setOrganization({id: val, nombreFantasia: value?.children, newClient: false});
                    setSearch(value?.children)
                    setIsNew(false)
                }

                combobox.closeDropdown();
            }}
            {...props}
        >
            <Combobox.Target>
                <InputBase
                    rightSection={loading ? <Loader size={size}/> : <Combobox.Chevron size={size}/>}
                    rightSectionPointerEvents="none"
                    leftSection={isNew ?
                        <Text cVariant={"surface-2"} fw={400}>Nuevo Cliente: </Text> : null}
                    leftSectionWidth={isNew ? 100 : 0}
                    value={search}
                    label={label}
                    size={size}
                    disabled={disabled}
                    onChange={(event) => {
                        combobox.openDropdown();
                        combobox.updateSelectedOptionIndex();
                        setSearch(event.currentTarget.value);
                    }}
                    onClick={() => combobox.openDropdown()}
                    onFocus={() => combobox.openDropdown()}
                    onBlur={() => {
                        if (runOnBlur) runOnBlur();
                        combobox.closeDropdown();

                        // if the focus is lost and the search input is empty
                        if (isStringEmpty(search)) {
                            setIsNew(false)
                            setOrganization({id: 0, nombreFantasia: ''})
                            setData([])
                            return
                        }

                        if (search === defaultValue && data[0]?.id !== 1) {
                            return;
                        }

                        if (data[0]?.id !== undefined) {
                            // if the focus is lost and the search results contain one or more organizations, select the first one
                            setIsNew(false)
                            setOrganization({id: data[0].id, nombreFantasia: data[0].nombre, newClient: false});
                            setSearch(data[0].nombre);
                        } else {
                            // if the focus is lost and the search results contain no organizations, set the new client
                            setIsNew(true)
                            setOrganization({id: 1, nombreFantasia: search, newClient: true});
                        }
                    }}
                    placeholder="Buscar..."
                />
            </Combobox.Target>

            <Combobox.Dropdown>
                <Combobox.Options mah={200} style={{overflowY: 'auto'}}>
                    {options}
                    {data?.length === 0 && search?.trim().length > 0 && (
                        <Combobox.Option value={"1"} key={"1"}>Nuevo Cliente: {search}</Combobox.Option>
                    )}
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
}

export default OrganizationAsyncSelect;