import React, {useContext, useEffect, useState} from 'react';
import organizationController from '../controller/organizationController';
import {TextInput} from "@mantine/core";
import OrganizationAsyncSelect from "./async_select/OrganizationAsyncSelect";
import Container from "./base/Container/Container";
import Title from "./base/Title/Title";
import {CLIENT_INITIAL_STATE} from "../models/models";
import {LoadingContext} from "../context/LoadingContext";


function ClientPanel({updateClientValues, preClient, inCW}) {
    const {setLoading} = useContext(LoadingContext);
    const [cliente, setCliente] = useState(preClient || CLIENT_INITIAL_STATE);
    const [selectedClient, setSelectedClient] = useState({id: 0, nombreFantasia: '', newClient: false});
    useEffect(() => {
        setSelectedClient({id: preClient?.id, nombreFantasia: preClient?.nombreFantasia, newClient: false})
    }, []);

    useEffect(() => {
        if (!inCW) {
            updateClientValues(cliente);
        }
    }, [cliente]);

    useEffect(() => {
        if (selectedClient?.id !== 0) {
            handleClientSelection(selectedClient);
        }
    }, [selectedClient]);

    function clienteValues(values) {
        setCliente(prevState => ({
            ...prevState,
            ...values,
        }));
    }

    const handleClientSelection = (client) => {
        const organizationId = client.id;

        if (client.newClient) {
            setCliente(() => ({
                ...CLIENT_INITIAL_STATE,
                nombreFantasia: client.nombreFantasia,
                nuevoCliente: 1,
            }));
        } else {
            if (!organizationId || organizationId === 1) return;

            setLoading(true);
            organizationController.getClient(organizationId)
                .then((response) => {
                    clienteValues(response);
                })
                .catch(() => {
                    console.error('Error fetching client data');
                });
            setLoading(false);
        }
    };

    return (
        <Container direction={"column"} wrap={"wrap"} p={8}>
            <Title order={4} cVariant={"primary"} ta={"center"} w={"100%"}>CLIENTE</Title>
            <div className={"mb-1 w-100"}>
                <OrganizationAsyncSelect label={"Nombre"} size={"xs"} defaultValue={cliente.nombreFantasia}
                                         disabled={inCW} organization={selectedClient}
                                         setOrganization={setSelectedClient}/>
            </div>
            <div className={"mb-1 w-100"}>
                <TextInput label={"Razón Social"} value={cliente.razonSocial} size={"xs"} disabled/>
            </div>
            <div className={"mb-1 d-flex gap-2 w-100"}>
                <div className={"w-100"}>
                    <TextInput label={"Dirección"} value={cliente.direccion} size={"xs"} disabled/>
                </div>
            </div>
            <div className={"mb-1 d-flex gap-2 w-100"}>
                <div className={"w-50"}>
                    <TextInput label={"RUT/CUIT"} value={cliente.rut} size={"xs"} disabled/>
                </div>
                <div className={"w-50"}>
                    <TextInput label={"Mail"} value={cliente.mail} size={"xs"} disabled/>
                </div>
            </div>
        </Container>
    );
}

export default ClientPanel;